.checkout-body {
    display: flex;
    flex-direction: row;
    max-width: 95vw;
    width: 920px;
    margin-left: auto; 
    margin-right: auto;
    background: #fff;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    border-radius: 8px;
    padding: 28px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.checkout-container {
    padding-bottom: 12px;
    max-width: 500px;
    width: 100%;
}

.StripeElement {
    border: 1px solid #CCC;
    height: 39px;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 4px;
}
  
  .checkout-confirm-details > div{
    margin-bottom: 12px;
  }

  #terms-text {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #183041;
    padding-left: 10px;
  }

  .terms-text {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #183041;
    padding-left: 10px;
    padding-top: 6px;
  }

#terms-link {
    color: #1F90FE;
    cursor: pointer;
}

#terms-link:hover {
    color: #4D4048;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.checkbox-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.checkbox-container.center {
    flex-direction: column;
    justify-content: center;
}

.mobile-note {
    color: #465967;
    margin-top: 4px;
    max-width: 408px;
    font-size: 11px;
    font-style: italic;
}

.checkout-hr {
    width: 100%;
    max-width: 416px;
    margin-right: auto;
    margin-left: 0;
}

.checkout-total-text,
.checkout-total-price {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: right;

    color: #183041;
}

.checkout-total-price {
    font-size: 28px;
}

.summary-details-header {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Muli', sans-serif;
    margin-bottom: 12px;
}

.stripe-form {
    padding: 0 15px;
}

.stripe-input {
    border-radius: 8px !important;
    padding-top: 10px;
}

.StripeElement--invalid {
    border-color: #dc3545 !important;
    background-color: #FFF1F1 !important;
}

.stripe-input,
input#full-name,
input#name,
textarea#card-input,
input#modal-line1 {
    width: 100% !important;
}

select#modal-state {
    width: 124px;
}

input#modal-city {
    width: 170px;
}

textarea#delivery-instructions {
    height: 100px !important;
    resize: none;
    padding: 8px;
    width: 408px;
    max-width: 100%;
}

.summary-product-description, .summary-product-price {
    font-size: 14px;
}

.summary-product-breakdown {
    font-size: 12px;
    opacity: 0.6;
}

.summary-total-row {
    color: #1F90FE;
}
.summary-total-text {
    font-size: 18px;
    padding-top: 8px;
}

.summary-subtotal-row {
    margin-bottom: 12px;
    font-size: 14px;
}

.hyperlink {
    color: #1F90FE;
    font-size: 14px;
    cursor: pointer;
}

.hyperlink:hover {
    text-decoration: underline;
}

.checkout-stage-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.checkout-stage-number {
    border: 2px solid #548BC5;
    border-radius: 25px;
    height: 30px;
    width: 30px;
    text-align: center;
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding-top: 2px;

    color: #548BC5;
}

.checkout-stage-name,
#confirmation-header {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: -0.16px;

    color: #1D2C3C;
    padding-left: 10px;
    padding-top: 4px;
}

#confirmation-header {
    padding: 0 !important;
    margin-bottom: 12px;
}

#confirmation-main > .checkout-stage-container {
    margin-bottom: 12px;
    margin-top: 24px;
}

.desktop-cart-summary,
.oc-cart-summary {
    width: 100%;
    box-sizing: border-box;
    border-radius: 9px;
    margin-top: -28px;
    margin-bottom: -28px;
    margin-right: -28px;
    padding: 28px 15px;
}

.desktop-cart-summary {
    border-left: 1px solid #DFE0EB;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
}

.cart-item {
    padding-top: 4px;
    padding-bottom: 4px;
}

.cart-items-container>div:nth-child(even) {
    background-color: #FAFBFE;
}

.checkout-main > .dashboard-save {
    margin-top: 40px;
    margin-bottom: 40px;
}

input#first-name,
input#last-name {
    width: 200px;
}

input#email,
input#password {
    width: 270px;
}

input#line1 {
    width: 275px;
}

input#phone,
input#line2 {
    width: 130px;
}

input#city,
select#state {
    width: 150px;
}

input#zipcode {
    width: 100px;
}

#fetch-shipping-logo {
    margin-top: -2px;
    margin-left: 12px;
}

#fetch-shipping-logo > img {
    width: 100px;
    height: auto;
}

#fetch-info-icon {
    display: inline-block;
    padding-left: 8px;
}

.checkout-section {
    margin-bottom: 20px;
    display: block;
}

.form-error {
    color: rgba(240, 18, 18, 0.8);
    min-height: 0px;
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4px;
    width: 100%;
}

.form-error-icon {
    margin-right: 4px;
}

#oc-did-you-know {
    margin: 40px auto 20px;
}

.desktop-cart-summary > #oc-did-you-know {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
}

#did-you-know-header {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #1D2C3C;
}

.did-you-know-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 24px;
}

.did-you-know-img {
    width: 45px;
    height: auto;
    margin-right: 15px;
}

.did-you-know-copy {
    font-family: 'Muli', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #183041;
}

@media screen and (min-width: 992px) {
    .checkout-main {
        min-width: 500px;
        width: 500px;
        padding-right: 28px;
    }
}

@media screen and (max-width: 991px) {
    .cart-summary {
        width: 100%;
    }
    .checkout-main {
        width: 100%;
        min-width: auto;
    }
    .checkout-container {
        margin-left: auto;
        margin-right: auto;
    }
    .checkout-body {
        width: 600px;
    }
}
