.landing {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}

h2 {
  font-family: "Danley", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 52px;
  line-height: 130%;
}

h4 {
  text-transform: none !important;
  font-size: 22px;
  line-height: 120%;
}

.landing-who-we-are {
  letter-spacing: -0.01em;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.landing-bundle-header {
  margin-bottom: 28px !important;
}

#landing-laundry-img {
  position: relative;
  margin-bottom: 192px;
}

#landing-laundry-img > img {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.landing-preferences-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.landing-preference {
  text-align: center;
}

.landing-subsection-header {
  font-size: 38px;
  margin-top: 52px;
  margin-bottom:12px;
}

.landing-section-header {
  font-weight: 500;
  font-size: 40px;
  line-height: 130%;
}

.landing-sign-in {
  text-align: center;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 28px */

  text-align: center;

  color: #183041;

  opacity: 0.8;
  margin-top: 20px;
}

.landing-main-header {
    font-family: 'Danley', serif;
    font-size: 62px;
    text-align: center;
}

.landing-top-section {
  margin: 0 !important;
  position: relative;
  height: 580px;
  display: block;
  width: 100%;
  border-bottom: 5px solid #C9CBDA;
  transition: all .5s ease-in;
}

.landing-top-section.coupon {
  height: 600px;
}

.landing-block-container {
  max-width: 1500px;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.landing-video-container {
   position: absolute;
   width: 100%;
   left: 0;
   top: 0;
   display: flex;
   flex-direction: row;
   justify-content: center;
}

#landing-video {
  height: 670px;
  border-radius: 1px;
  width: 100%;
  z-index: 4;
  object-fit: cover;
}

.video-overlay {
  z-index: 5;
  height: 670px;
  width: 100%;
  position: absolute;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), linear-gradient(180deg, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0) 100%);
}

.landing-logo-container {
  height: 54px;
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
    .landing-logo-container {
        margin-top: 8px;
        max-width: 80%;
    }
}

.landing-bundle-checkmark-container {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  display: table;
}

@media screen and (min-width: 768px) {
  .fixed.landing-header-space-top {
    top: 39px !important;
  }
}

@media screen and (max-width: 767px) {
  .landing-sign-in {
    font-size: 18px;
  }
  .landing-sign-in {
    margin-top: 28px;
  }
  .landing-mobile-hero-img {
    height: 280px;
    width: 100%;
    /* background-image: url('../assets/miscellaneous/landing-mobile.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.landing-faq-container {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  
}

.landing-faq > div {
  letter-spacing: -0.01em;
}

.faq-body > *  > * > a {
  color: #183041;
  text-decoration: underline;
}

.faq-body > *  > * > a:hover {
  text-decoration: none;
}

#landing-testimonial-container {
  min-height: 345px;
  background: #E4F1FF;
}

#landing-testimonial-carousel {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.carousel-indicators {
  bottom: -40px;
}

.carousel-indicators > li {
  width: 10px;
  height: 10px;
  border: 1px solid rgba(24, 48, 65, 0.8);
  border-radius: 50px;
}

.carousel-indicators > li.active {
  background-color: rgba(24, 48, 65, 0.8);
}

.landing-emoji {
  height: 25px;
  width: auto;
}

#landing-products-carousel {
  margin: 40px auto;
  max-width: 1110px;
}

.products-carousel-item {
  text-align: center;
}

.react-multiple-carousel__arrow {
  background: transparent !important;
}

.react-multiple-carousel__arrow::before {
  color: rgba(24, 48, 65, 0.8) !important;
  font-weight: bold;
}

.react-multiple-carousel__arrow--right {
  margin-right: -36px;
}

.react-multiple-carousel__arrow--left {
  margin-left: -36px;
}

.input-sublink-container {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-left: 4px;
}

.input-sublink {
  font-size: 12px !important;
  color: #1F90FE !important;
  cursor: pointer;
}

.input-sublink:hover {
  text-decoration: underline !important;
}

.landing-apt-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing-apt-input-container > input {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 20px;
  background: #FFFFFF;
  border: 1px solid rgba(214, 214, 214, 0.8);
  box-sizing: border-box;
  border-radius: 8px;
  width: 30%;
  text-align: center;
  height: 50px;
}

.gate-header {
    margin: 24px 0;
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: #465967;
}

.btn-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.btn-blue {
  background: #1F90FE;
  border-radius: 50px;
  height: 43px;
  border: 2px solid #1F90FE;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  width: 220px;
  text-align: center;
  cursor: pointer;
  margin-top: 16px;

  color: #F9F9F9;
}

#btn-404 {
  height: 75px;
  font-size: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-blue:hover {
  background: #177ad9;
  border: 2px solid #177ad9;
}

.img-404-tp {
  height: 240px;
  width: auto;
}

#btn-404 {
  width: 240px;
}

#covid-response-section {
  background: #fff;
}

.landing-cta {
  background: #00A664;
  border-radius: 8px;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F9F9F9;
  height: 55px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
}

#landing-plus-sign {
  margin: 14px 12px 0;
}

@media screen and (max-width: 1200px) {
  #landing-products-carousel {
    max-width: 900px;
  }
}

@media screen and (min-width: 992px) {
  #covid-response-section {
    position: relative;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: -169px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    width: 920px;
    z-index: 6;
  }
}

@media screen and (max-width: 991px) {
    #landing-products-carousel {
      max-width: 90%;
    }
    .react-multiple-carousel__arrow--right {
      margin-right: -16px;
    }
    
    .react-multiple-carousel__arrow--left {
      margin-left: -16px;
    }
    .landing-preferences-row {
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }
    .landing-preference {
      margin-bottom: 30px;
    }
    .landing-main-header {
      margin-top: 32px;
      font-size: 36px;
    }
    .landing-detail-wrapper.apt {
      margin-bottom: 0;
    }
    .landing-apt-input-container > input{
      margin-bottom: 8px;
    }
    .landing-subsection-header {
      font-size: 24px;
    }
    .video-overlay.mobile-small,
    .landing-video-container.mobile-small,
    #landing-video.mobile-small,
    #hero-img.mobile-small {
      height: 450px;
    }
    h2 {
      font-size: 28px;
    }
    h4 {
      font-size: 20px !important;
    }
    .landing-apt-input-container {
      flex-direction: column;
    }
    .landing-apt-input-container > input {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
}