.signin-modal-body {
    min-height: 360px;
    width: 540px;
    max-width: 95vw;
    border-radius: 12px;
    padding: 32px;
    overflow: visible;
    position: relative;
  }

  .signin-modal-section-header,
  .loading-modal-section-header {
      font-family: 'Muli', sans-serif;
      font-weight: normal;
      font-size: 12px;
      margin-bottom: 12px;
  }
  
  .gate-input {
    background: #FFFFFF;
    border: 3px solid #C9CBDA;
    box-sizing: border-box;
    border-radius: 4px;
    height: 50px;
    width: 275px;
    max-width: 100%;
    margin-bottom: 8px;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829);
  }
  
  .signin-modal-landing-parachute {
    height: 260px;
    top: 20px;
    right: 0px;
  }
  
  .signin-modal-cloud-dark,
  .signin-modal-cloud-light,
  .signin-modal-landing-parachute,
  .loading-modal-cloud-light {
    position: absolute;
  }
  
  .signin-modal-cloud-dark,
  .loading-modal-cloud-dark {
      right: 170px;
      top: 20px;
  }
  
  .signin-modal-cloud-light,
  .loading-modal-cloud-light {
    right: 40px;
    top: 260px;
  }
  
  .signin-modal-tab {
    top: -32px !important;
    right: -32px !important;
  }

  .signin-modal-alert {
    width: 343px;
    max-width: 100%;
    margin-top: 8px;
    height: auto;
    font-size: 14px;
    padding-top: 4px;
  }

  .signin-radio-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 240px;
    margin-top: 12px;
  }

  .signin-subtext {
    font-size: 14px;
    margin: 24px 0;
  }

  .signin-checkbox-container {
    display: flex;
    justify-content: flex-start;
    height: 65px;
    width: 205px;
    background-color: #fff;
    border: 3px solid #C9CBDA;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    border-radius: 8px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  .signin-checkbox-copy {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 15px;
    font-size: 18px;
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    color: #465967;
    letter-spacing: -0.01em;
  }
  @media screen and (min-width: 992px) {
    .signin-checkboxes-container {
      display: flex;
      flex-direction: row;
      width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  #sms-signin-container {
    margin-bottom: 20px;
  }

  #login-img {
    height: 86px;
  }

  .signin-alert {
    width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  .signin-loading-container {
    height: 120px;
    position: relative;
  }

  .phone-verification-container {
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    width: 337px;
    max-width: 95%;
    margin: 20px auto;
    padding: 35px 25px;
    background: #ffffff;
    position: relative;
  }

  .modal-input.phone-verification-input {
    width: 100%;
  }

  .phone-verification-btn-container {
    margin-top: 20px;
    text-align: center;
  }

  .phone-verification-btn-container > .interview-continue-button {
    margin-bottom: 10px !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  #order-complete-loading-container {
    position: relative;
    height: 400px;
  }

  @media screen and (max-width: 767px) {
    .loading-modal-cloud-dark {
      top: 120px;
      right: 120px;
    }
    .loading-modal-cloud-light {
      top: 60px;
      right: 0;
    }
    .signin-modal-cloud-dark {
      height: 20px;
      right: 68px;
      top: 24px;
    }
    .signin-modal-cloud-light {
      top: 72px;
      height: 20px;
      right: 0;
    }
    .signin-modal-landing-parachute {
      top: 0px;
      right: 0px;
      height: 92px;
      z-index: 4;
    }
  }

@media screen and (max-width: 991px) {
    .signin-tagline {
       width: 205px;
       margin-left: auto !important;
       margin-right: auto !important;
    }
}

  .label-radio {
    font-size: 18px;
    font-family: Muli, sans-serif;
    font-weight: 700;
    color: rgb(70, 89, 103);
    letter-spacing: -0.01em;
    display: block;
    position: relative;
    padding-left: 60px;
    cursor: pointer;
    height: 60px;
    width: 200px;
    text-align: left;
    padding-top: 17px;
  }

  .label-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .radio-check {
    position: absolute;
    top: 14px;
    left: 15px;
    height: 30px;
    width: 30px;
    border: 2px solid rgb(70, 89, 103);
    border-radius: 50%;
    background-color: #eee;
  }

  .label-radio input:checked ~ .radio-check {
    border: 2px solid rgb(70, 89, 103);
    background-color: white;
  }

  .radio-check:after {
    content: "";
    position: absolute;
    display: none;
  }

  .label-radio input:checked ~ .radio-check:after {
    display: block;
  }

  .label-radio .radio-check:after {
    top: 1px;
    left: 1px;
  	width: 24px;
  	height: 24px;
  	border-radius: 50%;
  	background-color: #0074D2;
  }