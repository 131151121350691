.header-btn-container {
    min-width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-right: 15px; */
}

.header-btn {
    height: 54px;
    min-width: 54px;
    margin-top: 20px;
    background-color: transparent;
    border-radius: 6px;
    box-sizing: border-box;
    border: none;
    color: #183041;
    padding-right: 15px;
    cursor: pointer;
}

.btn-green {
    height: 54px;
    width: 137px;
    margin-top: 20px;
    background-color: #00A664;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: "Muli", sans-serif;
    font-weight: bold;
    border: none;
    color: #fff;
    cursor: pointer;
}

.btn-green:hover,
.landing-cta:hover {
    background-color: #31af79;
}

.landing-header {
    z-index: 999999;
    display: block;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    max-width: none !important;
}

.header-btn:hover {
    text-decoration: underline;
    transition: text-decoration .3s ease-in;
}

.header-btn:active {
    transform: scale(.95);
}

@media screen and (max-width: 767px) {
    .landing-header {
        justify-content: space-around !important;
        box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.0490829), 0px -15px 35px rgba(43, 64, 78, 0.095505);
    }
}