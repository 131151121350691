.doc-dialog {
    height: 95%;
    width: 800px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 20px;
  }
  
  .doc-dialog iframe {
    height: 100%;
  }
  
  #doc-frame {
    width: 95%;
    height: 95%;
    padding-right: 12px;
  }

  .doc-dialog-btn {
    margin-top: 20px;
    height: 40px !important;
    width: 198px;
    margin-left: 15px;
    margin-right: 15px;
  }
  