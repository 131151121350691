#menu-container-desktop {
    min-height: 100vh;
    height: 100%;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    width: 240px;
    position: absolute;
    top: 0;
    z-index: 1;
}

#menu-container {
    background-color: #fff;
    border-bottom: 1px solid #dcdcdc;
    height: 100%;
    text-align: center;
}

#menu-container-mobile {
    display: none;
    width: 100%;
    max-width: 1110px;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

#menu-hamburger-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#menu-logo-desktop {
    text-align: center;
    width: 180px;
    margin: 32px auto;
}

#menu-logo-mobile {
    display: block;
    width: auto;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

#menu-hamburger {
    color: #4E5E6F;
    font-size: 24px;
}

#menu-hamburger:hover {
    cursor: pointer;
    color: #1F90FE;
}

.menu-icon {
    width: 80px;
    height: 28px;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 8px;
}

.menu-nav-row {
    height: 40px;
    padding-top: 6px;
    color: #A4A6B3;
    cursor: pointer;
    margin-left: 0px;
    margin-right: 0px;
}

.menu-nav-row:hover, .menu-nav-row.selected, .menu-nav-row.selected:hover {
    background-color: rgba(159,162,180,0.08);
    color: #7ABDFF;
}

.menu-nav-row.selected {
    color: #7ABDFF;
}
.menu-nav-row.selected:hover {
    background: rgba(159,162,180,0.08);

}

.menu-nav-link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    letter-spacing: 0.2px;
    padding-top: 4px;
    /* sidebar / gray */
    color: #566575 !important;
    text-align:left; 
}

.menu-nav-link:hover {
    color: #1F90FE !important;
    text-decoration: underline;
}

.dashboard-body-container {
    min-height: 100vh;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.dashboard-body-container.next-delivery {
    margin-bottom: 90px;
}

.menu-mobile-list {
    position: absolute;
    background-color: #fff;
    padding-bottom : 12px;
    right: 0px;
    width: 240px;
    border: 1px solid #dcdcdc;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 999;
}

.menu-mobile-list > * {
    padding-left: 12px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.menu-mobile-list > .menu-icon {
    padding-left: 12px;
}

#menu-home-icon {
    background-image: url('../assets/menu_icons/home_gray.svg');
}

.menu-nav-row:hover > #menu-home-icon {
    background-image: url('../assets/menu_icons/home_selected.svg');
}

#menu-parachute-icon {
    background-image: url('../assets/menu_icons/parachute_gray.svg');
}

.menu-nav-row:hover > #menu-parachute-icon {
    background-image: url('../assets/menu_icons/parachute_selected.svg');
}
.menu-nav-row:hover > #menu-feedback-icon {
    color: #7ABDFF;
}

#menu-edit-icon {
    background-image: url('../assets/menu_icons/my_account.svg');
}

#menu-feedback-icon {
    font-weight: bold;
    color: #183041;
}

.menu-nav-row:hover > #menu-faq-icon {
    background-image: url('../assets/menu_icons/faqs_selected.svg');
}

#menu-faq-icon {
    background-image: url('../assets/menu_icons/faqs.svg');
}

.menu-nav-row:hover > #menu-edit-icon {
    background-image: url('../assets/menu_icons/my_account_selected.svg');
}

#menu-logout-icon {
    background-image: url('../assets/menu_icons/logout_gray.svg');
}

.menu-nav-row:hover > #menu-logout-icon {
    background-image: url('../assets/menu_icons/logout_selected.svg');
}

#menu-contact-us {
    background-image: url('../assets/menu_icons/contact_gray.svg');
}

.menu-nav-row:hover > #menu-contact-us {
    background-image: url('../assets/menu_icons/contact_selected.svg');
}

.menu-mobile-list > a {
    color: #fff !important;
    cursor: pointer;
}

.menu-mobile-list > a:hover {
    opacity: 1;
}

.dashboard-orders {
    width: 100%;
    max-width: 1110px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
}

.dashboard-orders.add-products > div > .order.container {
    box-shadow: 0px 0px 15px rgba(85, 85, 85, 0.15);
}
.dashboard-orders.add-products {
    margin-top: 60px;
    margin-bottom: 60px;
}

.dashboard-header {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: #183041;
    width: 920px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
    .dashboard-header {
        width: 500px;
        margin-top: 25px;
    }
} 

.dashboard-headers-container {
    text-align: left;
    padding: 32px 15px;
}

.dashboard-headers-container > * {
    max-width: 1110px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.dashboard-main-header {
    font-family: 'Danley', serif;
    font-size: 28px;
    margin-bottom: 8px !important;
}

.dashboard-section-container {
    padding: 10px 20px 20px;
    background: #FFFFFF;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    color: #183041;
    width: 343px;
    max-width: 100%;
    height: fit-content;
    margin-top: 12px;
    margin-bottom: 30px;
}

.faq-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #DFE0EB;
    /* cursor: pointer; */
    
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;

}
.faq-header-text{
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
}

.faq-container{
    display: flex;
    flex-direction: column;
    padding-left: 39px;
    padding-right: 39px;
    padding-bottom: 100px;
    position: relative;
    max-width: 732px;
    top: 57px;
}

.faq-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.faq-spacing{
    height: 52px
}

.account-header-container {
    margin-left: 30px;
    margin-top: 30px;
}

.with-banner {
    padding-top: 100px !important;
    margin-top: 0 !important;
}

.account-header-container >img {
    height: 23px;
    width: auto;
    margin-top: auto;
    margin-bottom: 12px;
}

#account-header {
    font-size: 28px;
    margin-top: 12px;
    margin-bottom: 0 !important;
    margin-left: 12px;
    display: inline-block;
}

.order-history-header {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    color: rgba(24, 48, 65, 0.8);
    margin-top: 40px;
    margin-bottom: 20px;
}

.order-history-header.with-banner {
    margin-top: 100px;
}

.account-section-header-container {
    width: 343px;
}
.account-section-header-container > h4 {
    margin-bottom: 0 !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
}

input.invalid,
/* label.invalid, */
select.invalid,
input.invalid::placeholder,
select.invalid::placeholder,
.StripeElement--invalid {
    border-color: #dc3545 !important;
    background-color: #FFF1F1;
}

.account-sections-container > div {
    margin-right: auto;
    margin-left: 30px;
}
.account-sections-container {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-section-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.account-section-subheader {
    font-weight: bold;
    color: rgba(24, 48, 65, 0.8);
    margin-bottom: 10px;
}

.dashboard-section-header-row > div {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
}

.account-section-edit {
    color: #1F90FE;
    cursor: pointer;
    font-size: 14px;
}

#pause-icon {
    margin-bottom: 4px;
    margin-right: 4px;
}

.account-section-edit:hover {
    text-decoration: underline;
}

#menu-mobile-header {
    display: flex;
    justify-content: space-between;
}

.dashboard-progress-container {
    width: 170px;
    min-height: 42px;
    height: auto;
    background: #E4FFEE;
    border: 2px solid #00A664;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 8px 12px;
    text-align: center;
}

@media screen and (min-width: 992px) {
    .dashboard-body-container {
        margin-left: 240px !important;
    }
}

@media screen and (max-width: 374px) {
    .dashboard-progress-container {
        width: 136px;
    }
}

@media screen and (max-width: 375px) {
    .dashboard-progress-container {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 991px) {
    .account-sections-container > div {
        margin-left: auto;
        margin-right: auto;
    }
    .account-header-container {
        width: 343px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }
    #menu-container {
        width: 100%;
        position: relative;
    }
    #menu-container-desktop {
        display: none;
    }
    
    #menu-container-mobile {
        display: block;
    }


    .dashboard-body-container {
        margin-left: 0;
    }
}

.dashboard-status-icon {
    margin-right: 8px;
}

.skip-all-link {
    text-align: center;
}

.loading-icon {
    position: absolute;
    width: 6em;
    height: 6em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
  }
  .loading-icon :hover {
    cursor: wait;
  }
  .loading-icon .loading-icon__animated-stroke {
    stroke-dasharray: 627.3;
    animation: loading-icon-stroke 2.5s ease-in-out infinite, loading-icon-spin 2s linear infinite;
  }
  .loading-icon .loading-icon__static-stroke-section {
    stroke-dasharray: 50 577.3;
    stroke-dashoffset: 25;
    animation: loading-icon-spin 2s linear infinite;
  }

  .account-household-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
  }

  .interview-household-img.account-dash {
    width: 68px;
    height: 68px;
    margin: 24px 8px 10px;
    overflow: hidden;
}

.interview-household-img.account-dash > img {
    height: 50px;
    width: auto;
}

.interview-household-img.account-dash > img.child,
.interview-household-img.account-dash > img.pet {
    height: 42px;
    width: auto;
}
  
#phone-banner-container {
    background: #FFF2E4;
    position: absolute;
    height: 80px;
    top: 0;
    left: 0;
    right: 0;
}

#phone-banner-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

#phone-banner-copy {
    color: #693007;
    margin: 0px 10px;
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

#phone-banner-container:hover > * > #phone-banner-copy {
    text-decoration: underline;
}

@keyframes loading-icon-stroke {
    0% {
        stroke-dashoffset: 602.3;
    }
    100% {
        stroke-dashoffset: -602.3;
    }
}
@keyframes loading-icon-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes grow {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    50% {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
    }

    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}
.grow {
    animation: grow 2s ease-in-out infinite;
}
.referralTextarea {
    resize: none;
    height: 90px;
    border: 2px dotted rgba(0, 0, 0, 0.23);
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #FFFFFF;
    font-size: 16px;
    vertical-align: middle;
    padding: 7px;
    width: 100%;
    color: #465967;
    -webkit-appearance: none;
}
@media screen and (max-width: 1200px) {
    .referralTextarea {
        font-size: 14px;
        margin-left: 0px;
        margin-top: 5px;
        padding: 4px;
    }
}

.referralTextarea:focus {
    border: 2px dotted #0074D2;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
}