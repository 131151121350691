.bundle {
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    border-radius: 8px;
    margin-bottom: 40px;
    width: 500px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.bundle-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #DFE0EB;
    cursor: pointer;
    margin-bottom: 1rem;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
}

.bundle-header.rounded-bottom {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.bundle-header.details-view {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-bottom: 0;
}

.bundle-header.details-view > div > img {
    width: 35px;
    height: auto;
}

.bundle-header-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.bundle-header-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.bundle-header-subtotal,
.bundle-header-title, 
.bundle-header-unincluded {
    line-height: 140%;
    letter-spacing: -0.01em;
}

.bundle-product.last {
    padding-bottom: 1.5rem;
}

.bundle-header-subtotal,
.bundle-header-title,
.bundle-header-unincluded,
.bundle-product-type {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    color: #183041;
    padding-left: 13px;
}

.bundle-header-title {
    font-weight: bold;
    font-size: 20px;
}

.bundle-header-subtotal {
    font-weight: normal;
    font-size: 16px;
}

.bundle-header-img {
    height: 60px;
    width: 60px;
}

.bundle-product-type {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    margin-bottom: 1rem;
}

.bundle-product-price {
    margin-bottom: 10px;
}

.bundle-product-main {
    padding-left: 8px;
    padding-right: 8px;
}

.bundle-product-main,
.bundle-product-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bundle-product-body {
    flex-direction: column;
    text-align: left;
}

.bundle-product-details {
    padding-right: 8px;
}

.bundle-product-body-container {
    width: 100%;
    padding-left: 4px;
}

.bundle-product-select {
    background: #FFFFFF;
    height:42px;
    width: 160px;
    min-width: 160px;
    margin-top: 12px;
    cursor: pointer;
    border: 2px solid #183041;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 6px;
    background-image:
        url('../assets/miscellaneous/select_chevron.svg');
    background-position: 96% 50%;
    background-size: 12px;
    background-repeat: no-repeat;
        -webkit-appearance: none;
        -moz-appearance: none;
}

.bundle-product-name {
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 12px;
}

.product-only-offering {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;

    color: #FF974C;
}

.products-modal {
    background-color: #F9F9F9;
    height: 100%;
    width: 100%;
    max-width: 600px;
    right: 0;
    position: absolute;
}

.products-modal-back {
    transform: rotate(-90deg);
    font-size: 28px !important;
    position: absolute;
    left: 0px;
    top: 50%;
    bottom: 50%;
    cursor: pointer;
}

.products-modal-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

}

.products-modal-top-row {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    font-weight: bold;
    background-color: #fff;
    width: 100%;
    max-width: 600px;
    height: 70px;
    position: fixed;
    top: 0px;
    color: #0074D2;
}

.products-modal-body{
    position: absolute;
    top: 70px;
    padding: 20px;
    left: 0;
    right: 0;
    overflow-y: scroll;
    height: calc(100% - 70px);
    margin-bottom: 80px;
}

.bundle-footer-btn-container {
    border-top: 1px solid #DFE0EB;
}

.bundle-footer-btn-container.negative-margin {
    margin-top: -17px;
}

.bundle-footer-btn-container,
.bundle-footer-btn-container > button {
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
    text-decoration: underline;
}

.bundle-footer-btn-container:hover,
.bundle-footer-btn-container > button:hover {
    text-decoration: none;
}

.bundle-footer-btn-container > button {
    height: 68px;
    font-weight: 800;
    font-size: 18px;
    line-height: 21px;
    border: none;
    font-family: "Muli", sans-serif;
}

.everyday-bundle-header,
.everyday-footer-btn {
    background: #E4F1FF;    
    color: #00579E;
}
.everyday-manifest-bundle-product {
    background: #F1F7FD;
}

.kitchen-bundle-header,
.kitchen-footer-btn {
    background: #FFFACC;
    color: #605206;
}

.kitchen-manifest-bundle-product {
    background: #FEFCE8;
}

.laundry-bundle-header,
.laundry-footer-btn {
    background: #FFF2E4;
    color: #693007;
}

.laundry-manifest-bundle-product {
    background: #FFFAF3;
}

.defense-bundle-header,
.defense-footer-btn,
.defense-manifest-bundle-product {
    background: #E4FFEE;    
}
#everyday-bundle-container {
    border: 2px solid #0074D2;
}
.everyday-footer-btn:hover {
    background: #D6EAFF;
}
#kitchen-bundle-container {
    border: 2px solid #F5DF68;
}
.kitchen-footer-btn:hover {
    background: #FFF7B3;
}
#laundry-bundle-container {
    border: 2px solid #F5AD5D;
}
.laundry-footer-btn:hover {
    background: #FFE6CC;
}
#defense-bundle-container {
    border: 2px solid #6CD293;
}
.defense-footer-btn {
    color: #1A5631;
}
.defense-footer-btn:hover {
    background: #CCFFDF;
}

#change-products-modal,
#add-products-modal {
    max-width: 600px;
}


#add-products-modal-container {
    z-index: 10000;
}
#change-products-modal-container {
    z-index: 10001;
}

.price-details-container {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    width: 320px;
    overflow-y: scroll;
    max-height: 100%;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.price-details-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.desktop-cart-summary > .price-details-container,
.oc-cart-summary > .price-details-container {
    border: none;
    box-shadow: none;
    margin-right: auto;
    margin-left: auto;
}

.oc-cart-summary > .price-details-container,
.oc-cart-summary > * > .price-details-hr {
    width: 100%;
}

.price-details-container > .bundle-header {
    padding: 15px 20px;
    height: 67px;
}

.price-details-container.fixed-bottom {
    position: fixed;
    bottom: 90px;
    left: 12px;
    width: 320px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.price-details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
}

.price-details-header-text,
.details-view > div > div > .bundle-header-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}
.details-view > div > div > .bundle-header-subtotal {
    font-size: 12px;
    line-height: 140%;
}

.price-details-row > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
}

.price-details-row {
    padding: 12px 20px;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.price-details-product-name,
.price-details-line-price,
.price-details-subtotal-value {
    font-size: 14px;
}
.price-details-subtotal-label.coupon,
.price-details-subtotal-value.coupon {
    color: #00A664;
}
.price-details-subtotal-label.credit:hover {
    text-decoration: underline;
    cursor: pointer;
}
.price-details-line-price {
    text-align: right;
}
.price-details-pricing-breakdown {
    font-size: 12px;
}
.price-details-subtotal-label,
.price-details-total-label {
    text-align: right;
    font-weight: bold;
    font-size: 14px;
}
.price-details-total-label,
.price-details-total-value {
    font-size: 18px;
}
.price-details-close {
    cursor: pointer;
}
.price-details-hr {
    margin: 0 auto;
    width: 280px
}
.price-details-products-container>div:nth-child(even) {
    background-color: #FAFBFE;
}
.desktop-bundles-body {
    max-width: 95vw;
    width: 920px;
    margin-left: auto; 
    margin-right: auto;
    background: #fff;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    border-radius: 8px;
    padding: 28px;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex; 
}

.order-complete-line-price {
    margin-top: -8px;
}

.refill-notif-container {
    background: #E4F1FF;
    border: 2px solid #0074D2;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    width: 219px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: 2px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.refill-notif-container:hover {
    background: #B3D7FF;
}

.refill-notif-copy {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #00579E;
}

.price-details-header.not-modal {
    padding: 0 0 20px 0 !important;
}

.price-details-header.not-modal > .price-details-header-text {
    font-size: 18px !important;
}

@media screen and (min-width: 992px) {
    .bundle {
        margin-left: 0;
        margin-right: 0;
    }
    .bundle-product-body {
        flex-direction: row-reverse;
    }
    .bundle-product-body.no-dropdown {
        flex-direction: row;
    }
    .products-modal-body {
        padding: 20px 40px;
    }
}