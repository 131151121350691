.interview-outer,
.interview-modal-outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 60px;
}

.interview-modal-outer {
    padding-top: 24px;
}

.interview-outer:focus {
    outline: none;
}

.interview-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 20px 20px 20px;
    width: 100%;
    max-width: 1110px;
    margin-right: auto;
    margin-left: auto;
}

.interview-top-row.signin {
    padding-left: 0 !important;
    padding-top: 0 !important;
}

.interview-top-row.landing-header {
    padding: 0 !important;
}
.interview-top-row.header {
    padding-bottom: 0 !important;
    padding-top: 24px;
}

.interview-feedback-btn {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    cursor: pointer;
    font-size: 10px;
    /* or 14px */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;

    color: #183041;
    padding-top: 2px;

    opacity: 0.8;
    max-width: 100px
}

.feedback-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
}

.feedback-btn-container {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
}

.feedback-container.complete {
    bottom: 80px;
}

.interview-feedback-icon {
    font-size: 16px;
    margin-right: 2px;
    padding-bottom: 4px;
}

.interview-icon {
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.interview-inner,
.modal-inner {
    position: relative;
}

.interview-inner,
.modal-inner,
.interview-modal-inner {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding: 20px;
}

.interview-inner,
.interview-modal-inner {
    text-align: center;
    padding-bottom: 43px;
    padding-top: 0 !important;
    margin-top: -20px;
    max-width: 920px;
    width: 100%;
}

.modal-inner {
    max-width: 600px;
}

.button-back-arrow {
    opacity: 1;
}

.button-back-arrow > path {
    fill: #ffffff;
}

.button-back-arrow:hover {
    opacity: 1;
}

.button-back-arrow.carousel {
    z-index: 10;
}

.interview-back-arrow {
    opacity: 0.6;
    width: 20px;
    height: auto;
}
.interview-back-arrow:hover {
    opacity: 1;
}

.interview-back-arrow.carousel {
    z-index: 10;
}

.interview-section-header {
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    font-size: 12px;
}

.interview-question-header {
    font-family: 'Danley', serif;
    font-size: 28px;
    margin-top: 18px;
    padding-right: 4px;
    text-align: center;
}

.order-complete-header {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: #183041;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
}

.interview-question-header.final {
    padding-top: 60px;
}


.interview-question-detail {
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    opacity: 0.8;
}

.interview-continue-button {
    background: #0074D2;
    border-radius: 8px;
    box-sizing: border-box;
    min-width: 148px;
    height: 55px;
    padding: 0px 12px;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    align-self: center;
    margin: 10px auto;

    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;

    /* Text / Body */
    color: #F9F9F9;
    margin-bottom: 43px !important;
    cursor: pointer;
    border: none;
}

.flex-column > .interview-continue-button {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.interview-continue-button.small-text {
    font-size: 14px;
}

.complete-continue-button {
    height: 64px;
    background-color: #49CB93; 
    color: #fff;
    width: 313px;
    border-radius: 0 0 8px 8px;
    margin: 0 auto 0 auto;
    border: none;
}

.complete-continue-button:hover {
    background-color: #00A664;
}

.interview-continue-button:hover {
    background: #1F90FE;
}

.interview-continue-button:active {
    background: #00579E;
}
.interview-continue-button:disabled,
.interview-continue-button:disabled:hover,
.dashboard-save:disabled,
.dashboard-save:disabled:hover {
    background: #949494;
    border: #949494;
    cursor: default;
}

.interview-pill:hover {
    border-color: #1F90FE;
}

.interview-pill {
    cursor: pointer;
    display: inline-block;
    margin: 4px;
    border: 2px solid #dcdcdc;
    box-sizing: border-box;
    border-radius: 50px;
    background: #fff;
    font-size: 18px;
    line-height: 23px;
    width: 240px;
    height: 55px;
    text-align: center;
    padding-top: 14px;
    transition: all .2s ease-in-out;
}

.interview-pill.brand {
    width: 200px;
}

.order-bundle-header {
    font-size: 24px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.order-bundle-img {
    margin-top: 12px;
}

.interview-pill.selected {
    background: #183041;
    color: #fff;
}

.household-pill-container {
    position: relative;
    margin-right: 20px;
    margin-left: 20px;
}

.household-pill {
    border: 3px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 50px;
    background: #fff;
    height: 75px;
    width: 242px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-right: 48px;
    padding-left: 24px;
    margin-bottom: 21px;
}

.household-pill:hover,
.household-pill.active {
    border-color: #1F90FE;
}

.household-pill.invalid {
    border-color: #FF0000;
}

.household-pill-inner.img {
    justify-content: flex-end;
}

.household-pill-inner.date {
    justify-content: center;
    padding-bottom: 12px;
}

.household-pill-inner {
    display: flex;
    flex-direction: column;
}

.interview-copy-container {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.interview-copy {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */
    text-align: center;

    color: rgba(24, 48, 65, 0.8);
    max-width: 100%;
    /* Inside Auto Layout */
    flex: none;
    order: 2;
    align-self: center;
    margin: 43px 0;
}

.interview-copy.sixteen {
    font-size: 16px;
}

.interview-copy.small {
    font-size: 14px;
    margin: 20px auto !important;
    max-width: 90%;
}

.interview-copy.margin-small {
    margin: 32px 0 !important;
}

.interview-header-img {
    height: 100px
}

.household-rows-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.household-age-input{
    width: 74px;
    text-align: left;
    padding-left: 6px;
    margin-top: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 24px;
}

.prediction-group,
.summary-group {
    margin: 12px auto;
    border: 2px solid #dcdcdc;
    width: 313px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 8px 4px 4px 4px;
}

.prediction-group.top {
    padding: 20px 20px 0px 20px;
}

.prediction-thumbnail {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.prediction-thumbnail.dashboard {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.prediction-thumbnail > img{
    max-width: 100%;

}

.prediction-brand-thumbnail,
.product-detail-thumbnail {
    padding-right: 0px;
}

.product-detail-container {
    max-width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
}


.product-detail-thumbnail {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 120px;
    padding-top: 20px;
}

.prediction-brand-thumbnail img {
    max-width: 100%;
}

.product-detail-thumbnail > img {
    max-width: 100px;
    height: 100%;
    padding-top: 20px;
}

.product-detail-name {
    padding-top: 20px;
    font-size: 16px;
    color: #212529;
    padding-right: 10%;
}

.prediction-product-name {
    font-size: 14px;
    margin-bottom: 8px;
    padding-left: 12px;
    line-height: 180%;
}

.prediction-product-name.unselected {
    line-height: 140%;
}

.prediction-options-label {
    font-size: 12px;
    padding-top: 4px;
    padding-left: 8px;
}

.prediction-options-chevron, .prediction-button-row {
    text-align: right;
    padding-right: 8px;
    transition: all .2s ease-in-out;
}

.prediction-button-row:hover {
    fill: #FF974C;
}

.prediction-options-chevron {
    color: #D1D6D9;
}

.interview-counter-body, .prediction-counter-body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.prediction-counter.row {
    margin-left: 4px;
}

.prediction-counter-body {
    margin-top: 8px;
}

.interview-counter-body {
    margin-top: 40px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.interview-counter-value {
    font-family: 'Muli', sans-serif;
    font-size: 38px;
    width: 48px;
    text-align: center;
}

.interview-counter-button {
    width: 40px;
    height: 40px;
    margin: 4px 12px 0 12px;
    padding: 6px 4px 4px 4px;
    transition: all .2s ease-in-out;
}

.prediction-counter-button {
    font-size: 14px;
    width: 30px;
    height: 30px;
    margin: -8px 12px 0 8px;
    padding-top: 3px; 
}

.interview-counter-button, .prediction-counter-button {
    color: #1F90FE;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
    background: #FFFFFF;
    border: 2px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all .2s ease-in-out;
}

.interview-counter-button:hover, .prediction-counter-button:hover {
    background-color: #1F90FE;
    color: #fff;
}

.prediction-permanent-button {
    height: 12px;
}

.prediction-options-chevron:hover {
    color:#1F90FE;
}

.prediction-info-button {
    width: 100%;
    position: relative;
    text-align: right;
}

.prediction-info-button > img {
    position: absolute;
    top: 4px;
    right: 8px;
}

.manifest-other-options {
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    margin-left: 0px;
    margin-right: 0px;
}

.manifest-other-options:hover {
    background-color: #1F90FE;
    color: #fff;
}

.manifest-other-options:hover > * {
    color: #fff;
}

.manifest-other-options:hover > div > div > svg > path {
    fill: #fff;
}
.manifest-other-options:hover > div > div > svg > circle {
    stroke: #fff;
}

.interview-slider.MuiSlider-marked{
    margin-bottom: 0px;
}

.interview-slider > .MuiSlider-markLabel {
    display: none;
}

.interview-slider-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.interview-slider-arrow {
    padding-left: 30px;
}

.interview-feedback-container,
.signin-modal-tab-container,
.interview-modal-body {
    /* width: 100%; */
    /* position: relative; */
}

.interview-modal-body {
    padding-top: 12px;
}

.signin-modal-tab {
    height: 25px;
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 0.1em;
    padding: 6px;
    position: absolute;
    top: -20px;
    right: -20px;    
    background-color: #FF974C;
    color: #fff;
    box-sizing: border-box;
    border-radius: 0px 8px 8px 8px;
    cursor: pointer;
    font-weight: 700;
    text-align: center;
    border-top: none !important;
    top: -38px;
    background: #fff;
    color: #CCCCCC;
    border: 1px solid #CCCCCC;
    width: 80px;
}

.interview-continue-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 250px;
    margin: 43px auto;
}

.interview-enter-text {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    color: #183041;

    opacity: 0.8;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    align-self: center;
    margin: 10px 0px;
}

.interview-enter-text.disabled {
    color: #dcdcdc;
}

.signin-modal-tab:hover {
    border: 1px solid #183041;
    color: #183041;
    background: transparent;
}

.interview-feedback-body {
    font-size: 16px !important;
    color: #465967 !important;
    text-align: left;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.interview-feedback-input {
    width: 100%;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 6px;
    height: 200px;
    resize: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    background-color: #E1E4E9;
    color: #465967 !important;
    font-size: 16px !important;
}

.interview-feedback-input:focus,.interview-pill:focus, h2:focus {
    outline: none;
}

.interview-pill, h2 {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; 
}

.prediction-product-name-detail {
    opacity: 0.8;
    font-size: 12px;
    font-weight: normal;
}
.prediction-product-name-detail.sub {
    font-size: 11px;
}

.prediction-group.zero > div {
    opacity: 0.5;
}

.prediction-group.zero:hover > div {
    opacity: 1;
}

.more-options-row {
    padding-bottom: 10px;
    padding-top: 4px;
}

.prediction-total-price {
    font-size: 16px;
}


.household-icons-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.household-icons-row.stays-home {
    margin-top: -24px;
}

.interview-household-btn {
    background: #FFFFFF;
    border: 3px solid #C9CBDA;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829);
    border-radius: 8px;
    padding: 15px 20px;
    width: 170px;
    height: 90px;
    margin: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all .2s linear;
}

.interview-household-btn:active, .covid-alert:active {
  transform: scale(.9);
}

.interview-household-btn > * {
    margin-top: auto;
    margin-bottom: auto;
}

.interview-household-img {
    width: 75px;
    height: 75px;   
    background: #FFFFFF;
    border: 3px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 6px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.interview-household-img > img {
    max-height: 100%;
    width: auto;
}
.interview-household-img:hover,
.interview-household-img.active {
    border-color: #1F90FE;
}

.badge-row {
    display: flex;
    flex-wrap: wrap;
    max-width: 80px;
    padding-top: 4px;
    margin: 0px auto 8px;
    justify-content: center;
}

.badge-row.choices {
    padding-top: 0px;
}

.badge-row.detail {
    max-width: 100%;
    display: block;
}

.badge-row.detail > div {
    display: inline-block;
}

.badge-row > div > img {
    width: 20px;
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 4px;
    margin-right: 4px;
}

.badge-row.detail > div > img {
    width: 32px;
    margin-left: 4px;
    margin-right: 4px;
}

.household-x-icon {
    position: absolute;
    top: 0;
    right: -20px;
    cursor: pointer;
}

.household-pet-wrapper {
    position: relative;
    padding-right: 16px;
    display: block;
}

.pet-x-icon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 12px;
}

.tiers-container,
.kitchen-pills-container,
.trash-container {
    display: flex;
    flex-direction: column;
    width: 280px;
    margin-left: auto;
    margin-right: auto;
}

.trash-container {
    width: 100%
}

.kitchen-pills-container {
    margin-bottom: 43px;
}

.tier-pill-wrapper,
.trash-row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.trash-row-wrapper {
    margin: 20px auto;
}
.trash-row-wrapper > div {
    margin: auto 28px;
}

.trash-icon {
    width: 60px;
    height: 84px;
    text-align: center;
    position: relative;
}

.trash-icon > p {
    margin-top: 14px;
    margin-bottom: 0 !important;
    font-size: 18px;
}

.tier-rank {
    width: 20px;
    text-align: left;
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    padding-top: 16px;
}

.tier-emoji {
    padding-top: 4px;
    height: 55px;
    width: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tier-emoji > img {
    height: 24px;
    width: auto;
}

.product-icon {
    margin-right: 4px;
}

.progress-bar {
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: #fff;
    border-bottom: 1px solid #dcdcdc;
    height: 10px;
    z-index: 1000;
}

.progress{
    background-color: #49CB93;
    height: 100%;
    border-radius: 0;
}

.more-info-copy {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
}

.household-icons-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}


@media screen and (min-width: 768px) {
    .header-btn-mobile {
        display: none;
    }
}
@media screen and (max-width: 767px) {
    .household-icons-container {
        height: 174px;
    }
    .interview-enter-text {
        display: none;
    }
    .header-btn-mobile { 
        margin-left: 15px;
    }
    .household-icons-row {
        margin-left: -8px;
        margin-right: -8px;
    }
}

.product-detail-header {
    font-family: 'Danley', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    /* or 36px */
    letter-spacing: -0.01em;
    color: rgba(24, 48, 65, 0.8);
}

.product-detail-header,
.product-detail-badges-header,
.badge-row.detail {
    text-align: left !important;
    margin-top: 12px;
}

.product-detail-copy {
    text-align: left !important;
    margin-left: 10px !important;
    margin-right: 10px !important!;
}

.product-detail-badges-header {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    color: rgba(24, 48, 65, 0.8);
    padding-left: 5px;
}

.prediction-only-option {
    text-align: center;
    font-size: 11px;
    width: 100%;
    color: #FF974C;
    margin-bottom: 4px;
    padding-top: 6px;
    padding-bottom: 4px;
}

.prediction-modal-header {
    font-family: 'Danley', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */
    letter-spacing: -0.01em;

    color: rgba(24, 48, 65, 0.8);
}

.prediction-modal-form-row { 
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 12px auto;
}

.modal-prediction-question {
    margin-bottom: 40px;
}

.prediction-modal-radio {
    background-color: transparent;
    height: 16px;
    width: 16px;
    border-radius: 50px;
    border: 1px solid rgba(24, 48, 65, 0.8);
    margin-top: 3px;
    margin-left: 4px;
    cursor: pointer;
}

.prediction-modal-radio:hover,
.prediction-modal-radio.selected {
    background-color: #183041;
}

.prediction-modal-label {
    margin-left: 12px;
}

.prediction-modal-body {
    padding-bottom: 40px;
}

.complete-continue-button {
    width: 313px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
    margin-top: 20px;
}

.prediction-loading {
    margin-top: 80px;
}

.more-choices-container {
    width: 313px;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: -6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #f9f9f9;

    border-top: 1px solid #dcdcdc;
    border-left: 2px solid #dcdcdc;
    border-right: 2px solid #dcdcdc;
    border-bottom: 2px solid #dcdcdc;
}

.more-options-top-row {
    width: 100%;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: -6px;
    transition: all .2s ease-in-out;
}

.more-options-top-row:active,
.more-options-top-row:hover,
.more-options-top-row:hover > .prediction-options-chevron {
    color: #1F90FE;
}

.prediction-info-button > svg > circle,
.prediction-info-button > svg > path {
    transition: all .2s ease-in-out;
}

.prediction-info-button:hover > svg > circle {
    stroke: #FF974C !important;
}
.prediction-info-button:hover > svg > path {
    fill: #FF974C !important;
}

.checked-list {
    margin-bottom: 40px;
}

.checked-list-item {
    margin-bottom: 12px;
}

.checked-list-item > i {
    color: #49CB93;
    margin-right: 8px;
}

@keyframes spin-back {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

#loading-house {
    position: relative;
    z-index: 2;
}

#loading-cog {
    animation: spin-back infinite 7s linear;
    position: absolute;
    left: 48%;
    top: -50px;
    z-index: 1;
}

#outdoor-trash-img > img {
    height: 80px;
    width: auto;
}

.interview-modal-button-row {
    text-align: center;
    margin-top: 48px;
}

.cancel-modal-button-row {
    text-align: left;
}

.cancel-modal-body {
    padding-bottom: 20px;
    margin-top: -8px;
    font-size: 14px;
}

.modal-input-label {
    font-size: 12px;
    color: rgba(24, 48, 65, 0.8);
}

.cancel-modal-body > hr {
    padding-bottom: 8px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
}

.interview-modal-button-row > button {
    margin-left: 12px;
    margin-right: 12px;
}

.interview-modal-top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.trash-info-icon {
    position: absolute;
    top: 0;
    right: -20px;
}

.bundle-box {
    width: 100%;
    max-width: 730px;
    padding: 20px;
    background-color: #ffffff;
    border: 2px solid #DFE0EB;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    cursor: pointer;
}

.bundle-box.blue {
    background-color: #CCE6FF;
    border: none;
}

.bundle-box.blue, .bundle-box.opaque {
    height: 106px;
}

@media screen and (max-width: 605px) {
    .bundle-box.blue, .bundle-box.opaque {
        height: 134px;
    }
}

.bundle-box.blue > p {
    color: #183041 !important;
}

.bundle-box.opaque {
    opacity: 0.6;
}

.bundle-box-img {
    padding-right: 12px;
}

.green-bullet {
    background: #00A664;
    height: 10px;
    width: 10px;
    border-radius: 25px;
    display: inline-block;
    margin-right: 4px;
}

.bundle-box-product {
    margin-right: 8px;
    
}
@media screen and (max-width: 374px){
    .bundle-box-product {
        font-size: 15px
    }
} 

.bundle-box-product.bundle-box-landing {
    display: table-row;
    line-height: 160%;
}

.bundle-box-row {
    display: flex;
    flex-wrap: wrap;
}

.bundle-box-row > div {
    padding-top: 8px;
}

.bundle-box-col {
    padding-left: 16px;   
}

.header-filler {
    width: 19px;
}
