.testimonial-card-container {
    overflow: visible;
}
@media screen and (min-width: 992px) {
    .testimonial-card-container-centered {
        justify-content: center;
    }
}

.testimonial-card-dot-list {
    top: 0;
}

.testimonial-card-dot-list > li > button {
    border: none;
    /* borderDark */
    background: #C9CBDA;
    height: 10px;
    width: 10px;
}

.testimonial-card-dot-list > li.react-multi-carousel-dot--active > button {
    /* titleText */
    background: #465967;
}